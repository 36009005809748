.custom-button {
  background-color: #262626;
  color: white;
}

.custom-button:disabled {
  background-color: #d3d3d3;
  color: #7a7a7a;
}

.custom-button:hover {
  background-color: #262626;
  color: white;
}

.chip {
  display: inline-flex;
  align-items: center;
  height: 45px;
  padding: 8px 16px;
  border-radius: 18px;
  background-color: #f5f5f5;
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  margin: 4px;
}

.logo-icon {
  width: 356.715px;
  height: 50px;
}

.chip .close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #d8d8d8;
  color: #555555;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.chip .close-icon:hover {
  background-color: #c0c0c0;
}

.ssn-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 360px;
  padding-left: 16px;
  padding-right: 16px;
  /* padding:; */
  /* gap: 4px; */
  background: #edf7f2;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.ssn-header {
  width: 211px;
  height: 28px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #05945b;
}

.ssn-text {
  width: 284px;
  height: 48px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.css-pjrof6-MuiAvatar-root {
  background-color: #edf7f2 !important;
}

/* .MuiSwitch-track {
  background-color: #bb3330 !important;
} */

footer {
  padding: 10px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
}
