.container {
    padding-top: 10px !important;
}

.claims-container {
    background-color: #F5F5F5 !important;
    border-radius: 0.5rem !important;
    /* width: 486px; */
}
.content {
    background-color: #fff;
}