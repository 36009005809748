.h-image {
  width: 247.67px;
  margin-bottom: 8px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.date-grid {
  background: #F5F5F5;
  height: 60px;
  margin-left: 0px;
  margin-bottom: 16px;
}
.card-divider {
  background-color: #E3E3E3;
  height: 1px solid;
  width: 365px
}
.card-content {
  margin: auto;
  /* padding-bottom: 1rem; */
  justify-items: center;
  justify-content: center;
  height: 70px;
}