.custom-button {
    background-color: #262626;
    color: white;
}

.custom-button:disabled {
    background-color: #d3d3d3;
    color: #7a7a7a;
}

.custom-button:hover {
    background-color: #262626;
    color: white;
}