.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader {
    max-width: 374px;
    min-width: 300px;
    max-height: 444px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-image {
    max-width: 100%;
    max-height: 100%;
}

.container-ref {
    height: 292px;
    width: 290px;
    margin-top: 42px;
}