.sidebar {
    width: 282px;
}

.listItemText {
    color: #E3E3E3;
    ;
}

.drawer {
    width: var(--drawerWidth);
    flex-shrink: 0;
    background-image: url(../assets/Images/side-bar.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    width: 282px;
}

.selectedListItem {
    background: #FFFF !important;
    border-bottom: 1px solid red;
    /* padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)}; */
}

.selectedListItem .MuiListItemIcon-root {
    color: #0F0F0F !important;
}

.selectedListItem .MuiListItemText-primary {
    color: #0F0F0F !important;
    font-weight: 500;
}

.ListItem .MuiListItemIcon-root {
    color: White;
}

.ListItem .MuiListItemText-primary {
    color: White;
    font-weight: 500;
}

.sidebar-icon {
    height: 24px;
    width: 24px;
}
.nav {
    text-decoration: none;
}

.popover-content {
    display: flex;
    justify-content: space-between;
  }
  
  .section {
    flex: 1;
    margin-right: 10px; /* Add desired margin */
  }
  .chip {
    background-color: #0F0F0F
  }
