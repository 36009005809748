.dialogTitleText {
  color: #333333;
  width: 100%;
  text-align: center;
  margin-left: 1.5rem;
  padding: 0.5rem 0 0 0;
  font-size: 1.25rem;
  font-weight: 600;
  padding-left: 16px;
  line-height: 36px;
  margin-top: 24px;
}

.unCommonDialogTitle {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #434349;
}

.closeIcon {
  color: #434349;
  font-size: 1.3rem;
}

.closeButton {
  position: absolute;
}

.highlightOffIcon {
  color: #434349;
}

/* .dialogActionsRoot {
  background-color: #f7f7fa;
  padding: 15px;
  padding-right: 30px;
} */

.paperWidthMd {
  width: 50%;
  border-radius: 0.5rem;
}

.dialogActionsRoot {
  justify-content: space-between;
  /* This will spread the buttons apart */
}