@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("./theme/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

/* @font-face {
  font-family: "AvenirLTProBlack";
  src: local("AvenirLTPro-Black"),
    url("./theme/avenir-lt-pro/AvenirLTProBlack.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProBlackOblique";
  src: local("AvenirLTProBlackOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProBlackOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProBook";
  src: local("AvenirLTProBook"),
    url("./theme/avenir-lt-pro/AvenirLTProBook.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProBookOblique";
  src: local("AvenirLTProBookOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProBookOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProHeavy";
  src: local("AvenirLTProHeavy"),
    url("./theme/avenir-lt-pro/AvenirLTProHeavy.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProHeavyOblique";
  src: local("AvenirLTProHeavyOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProHeavyOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProLight";
  src: local("AvenirLTProLight"),
    url("./theme/avenir-lt-pro/AvenirLTProLight.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProLightOblique";
  src: local("AvenirLTProLightOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProLightOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProMedium";
  src: local("AvenirLTProMedium"),
    url("./theme/avenir-lt-pro/AvenirLTProMedium.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProMediumOblique";
  src: local("AvenirLTProMediumOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProMediumOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProOblique";
  src: local("AvenirLTProOblique"),
    url("./theme/avenir-lt-pro/AvenirLTProOblique.otf") format("openType");
}
@font-face {
  font-family: "AvenirLTProRoman";
  src: local("AvenirLTProRoman"),
    url("./theme/avenir-lt-pro/AvenirLTProRoman.otf") format("openType");
}  */

html,
body,
#root {
  height: 100%;
  /* font-family:  */
}

html,
body,
* {
  font-family: "Inter", serif;
}

body {
  margin: 0;
  font-family: "AvenirLTProBook", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #454545;
  border-radius: 10px;
}

.scrollcards::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollcards::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.scrollcards::-webkit-scrollbar-thumb {
  background-color: #11a74d;
  border-radius: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loginImage {
  background-image: url("assets/Images/authimage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.Cltxt span{
  font-weight: 500;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

