.custom-button {
    background-color: #262626;
    color: white;
}

.custom-button:disabled {
    background-color: #d3d3d3;
    color: #7a7a7a;
}

.custom-button:hover {
    background-color: #262626;
    color: white;
}

.assist-grid {
    background-color: #F5F5F5;
    height: 112px;
    border-radius: 16px;
    padding-top: 15px;
    width: 375px;
}

.info-icon-image {
    display: none;
}

.info-icon:hover+.info-icon-image {
    display: block;
}

.tooltip-container::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 81%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #E3E3E3 transparent;
}

